<template>
  <div class="container-inline-inline-scroll">
    <div class="container-inline-inline-scroll__video-full" :class="align">
      <VideoMcpViewer :thumb="thumb" :regular-player="regular" :mcp-id="mcp" :video-uniq-id="videoUniq" :play-video="false" class="container-video-multimedia" :bg-loop="false" />
    </div>
  </div>
</template>

<style lang="scss">
  .container-inline-inline-scroll {
    position: relative;
    width: 100%;
    margin: 0 0;

    @media (max-width: 575.98px) {
      height: auto;
    }

    &__video-full {
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: center top; 

      &.right {
        object-position: 70% top !important;
      }
      
      &.left {
        object-position: 20% top !important;
      }
    }

    .legend {
      position: relative;
      margin: 0 auto;
      padding: 10px;
      font-size: 20px;
      font-weight: 300;
      font-family: 'Roboto Slab';
      color: #333;
      background: #ffffffe8;
      width: 100%;
      max-width: 560px;
      text-align: center;
      box-shadow: 0px 0px 2px 2px #33333314;


      &.left {
        margin: 0 auto 0 5% !important;
        max-width: 440px;
      }

      @media (max-width: 575.98px) {
        &.left {
          margin: 0 auto 0 auto !important;
          max-width: 480px;
        }
      }
    }
  }
</style>

<script>

  import VideoMcpViewer from '@/components/VideoMcpViewer'
  
  export default {
    name: 'VideoInline',
    props: {
      src: {
        type: String,
        default: ``
      },
      autoplay: Boolean
    },
    components: {
      VideoMcpViewer
    },
    computed: {
      videoUniq() {
        return `video_scroll_${this._uid}`
      },
      mcp() {
        const path = this.src.split(';')
        if(path[0])
          return path[0]
        return this.src
      },
      thumb() {
        const path = this.src.split(';')
        if(path[1])
          return path[1]
        return ``
      },
      regular() {
        const path = this.src.split(';')
        if(path[2])
          return true
        return false
      },
      align() {
        const path = this.src.split(';')
        if(path[3])
          return path[3]
        return false
      }
    }
  }
</script>