export default {
  methods: {
    countLink(link, event) {
      window.dataLayer.push({
        event: event,
        event_action: link
      })
    }
  }
}
