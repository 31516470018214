<template>
  <div class="wrapper-perfil">
    <div><Navbar :values="article" /></div>
    <MainNominados :nominado="nominado" />
    <Footer />
  </div>
</template>

<script>

import find from 'lodash/find'

import MainNominados from '@/pages/MainNominados'
import Navbar from '@/components/Navbar'
import Footer from '@/pages/Footer'

export default {
  name: 'PerfilNominado',
  components: {
    MainNominados,
    Navbar,
    Footer
  },
  data() {
    return {
      nominado_id: 'all'
    }
  },
  mounted() {
    this.nominado_id = this.$route.params.id
  },
  computed: {
    article() {
      return require(`@/i18n/es.json`)
    },
    nominados() {
      return require(`@/i18n/nominados.json`)
    },
    nominado() {
      return find(this.nominados, ['id', this.nominado_id])
    }
  }
}
</script>
