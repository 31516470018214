<template>
  <div class="container-perfil container-fade" id="perfil">
    <div v-if="nominado" class="container">
      <img width="120px" class="position-absolute d-inline-block mb-5" :src="`${publicPath}assets/images/stars-right.png`" />
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <h2>{{ nominado.page_section }}</h2>
          <img width="100px" :src="`${publicPath}assets/images/estrellas.png`" />
        </div>
      </div>

      <div v-if="nominado" class="row justify-content-center mt-3">
        <div class="col-12 col-md-6">
          <div class="mt-5">
            <div class="d-flex d-md-none align-self-center mb-1 border-yellow pb-3">
              <div class="align-self-center">
                <h3 class="align-self-center">{{ nominado.name }}</h3>
                <h4 v-if="true" class="nominados-title align-self-center">
                  <router-link :to="{name: nominado.category_id}" custom v-slot="{ navigate, href }">
                    <a :href="href">{{ nominado.label }}</a>
                  </router-link>
                </h4>                 
              </div>
              <div class="circle-image align-self-center ml-3">
                <img width="150px" :src="`${publicPath}assets/images/nominados/${nominado.id}.png`" />
              </div>
            </div>
          </div>
        </div>
      </div> 
    
      <div v-if="nominado" class="row justify-content-center pb-5 border-yellow">
        <div class="col-12 col-md-7">
          <div>
            <VideoInline :autoplay="false" video-uniq-id="`${univisionarios-video}_about_1`" :src="nominado.video" />
          </div>

          <div class="row mb-5">
            <div class="col-md-6 col-12 align-self-start">
              <div class="figure" v-html="nominado.figure_1"></div>
            </div>
            <div class="col-md-6 col-12 align-self-start">
              <div class="figure" v-html="nominado.figure_2"></div>
            </div>
          </div>

         
        </div>

        <div class="col-md-5 col-12 align-self-center">
          <div class="d-md-flex d-none align-self-center mb-3 border-yellow pb-3">
            <div class="align-self-center">
              <h3 class="align-self-center">{{ nominado.name }}</h3>
              <h4 v-if="true"  class="nominados-title align-self-center">
                <router-link :to="{name: nominado.category_id}" custom v-slot="{ navigate, href }">
                  <a :href="href">{{ nominado.label }}</a>
                </router-link>
              </h4>              
            </div>
            <div class="circle-image align-self-center ml-3">
              <img width="150px" :src="`${publicPath}assets/images/nominados/${nominado.id}.png`" />
            </div>
          </div>        

          <div class="pt-3" v-html="nominado.bio"></div>
         
        </div>
      </div>    
      
      <div v-if="nominado && nominado.video_about_1" class="row justify-content-center">
        <div class="col-12 text-center">
          <h4 class="title-coverage">Conoce más de su historia aquí:</h4>
        </div>
        <div class="col-12 col-md-9 col-lg-7">
          <div class="video" v-if="nominado.video_about_1">
            <VideoInline :play-video="false" video-uniq-id="`${univisionarios-video}_video_about_1`" :src="nominado.video_about_1" />
          </div>
        </div>
      </div>
      <img width="120px" class="position-absolute-bottom-right d-inline-block mb-5" :src="`${publicPath}assets/images/stars-right.png`" />
    </div>

    <div v-if="nominado" class="container-fluid container-nominados-list mt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-12 text-center">
            <router-link :to="{name: 'homenajeados'}" custom v-slot="{ navigate, href }">
              <a class="link-nav-univ text-white text-uppercase"  id="homenajeados-nav" :href="href" @click="countLink(`prefooter-homenajeados`, 'general_click')">Volver a homenajeados</a>
            </router-link>
          </div>
        </div>   
      </div>
    </div>
  </div>
</template>

<style lang="scss">

  .container-nominados-list {
    background-color: #000;
    padding: 45px 0;
    color: #fff;
  }

.mb-5, .my-5 {
  margin-top: 1rem !important;
}

  .container-perfil {
    margin-top: 220px;

    .link-nav-univ {
      font-family: "AkiraExpandedSuperBold";
    }

    h2 {
      color: #000;
      font-family: var(--app-header-font);
      font-size: 31px;      
      text-align: center;
      line-height: 1;
    }

    h3 {
      font-size: 24px;
      font-family: "AkiraExpandedSuperBold";
    }

    .nominados-title {
      font-size: 33px;
      color: #bd9767ff;
      text-transform: uppercase;
      font-family: "TuskerGroteskBold";

      a {
        color: #bd9767ff;
      }
    }

    .figure {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-family: "AkiraExpandedSuperBold";

      span {
        padding-right: 10px;
        font-size: 41px;
        color: #bd9767;
        line-height: 1em;

        label {
          display: block;
          font-size: 12px;
          line-height: 0.1em;
        }
      }
    }

    .btn-round {
      color: #fff;
    }

    .title-coverage {
      color: #bd9767;
      font-weight: 600;
      font-size: 21px;
      margin: 15px 0;
    }
  }

</style>

<script>

  import analytics from '@/mixins/analytics.js'
  import VideoInline from '@/components/VideoInline'

  export default {
    name: 'MainNominados',
    mixins: [analytics],
    components: {
      VideoInline
    },
    data () {
      return {
        publicPath: process.env.BASE_URL
      }
    },
    props: {
      nominado: {}
    }
  }
</script>
